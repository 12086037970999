export const GET_ALL_SEGMENTS_ERROR = "GET_ALL_SEGMENTS_ERROR"
export const GET_ALL_SEGMENTS_LOADING = "GET_ALL_SEGMENTS_LOADING"
export const GET_ALL_SEGMENTS_SUCCESS = "GET_ALL_SEGMENTS_SUCCESS"
export const DELETE_SEGMENT = "DELETE_SEGMENT"
export const DELETE_SEGMENT_ERROR = "DELETE_SEGMENT_ERROR"
export const ADD_NEW_SEGMENT = "ADD_NEW_SEGMENT"
export const UPDATE_SEGMENT = "UPDATE_SEGMENT"
export const UPDATE_SEGMENT_CUSTOMER = "UPDATE_SEGMENT_CUSTOMER"
export const RESET_ADD_SEGMENT = "RESET_ADD_SEGMENT"
export const GET_SEGMENT_BY_ID = "GET_SEGMENT_BY_ID"
export const RESET_SEGMENT_DATA = "RESET_SEGMENT_DATA"

export const GET_SEGMENT_BY_CUSTOMER_LOADING = "GET_SEGMENT_BY_CUSTOMER_LOADING"
export const GET_SEGMENT_BY_CUSTOMER_SUCCESS = "GET_SEGMENT_BY_CUSTOMER_SUCCESS"
export const GET_SEGMENT_BY_CUSTOMER_ERROR = "GET_SEGMENT_BY_CUSTOMER_ERROR"

export const UPDATE_SEGMENT_BY_CUSTOMER_LOADING = "UPDATE_SEGMENT_BY_CUSTOMER_LOADING"
export const UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS = "UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS"

export const UPDATE_SEAT_TYPE = "UPDATE_SEAT_TYPE"
export const UPDATE_TICKET_TYPE = "UPDATE_TICKET_TYPE"
export const UPDATE_BOOKING_SOURCE = "UPDATE_BOOKING_SOURCE"
export const UPDATE_PAYMENT_MODE = "UPDATE_PAYMENT_MODE"
export const UPDATE_MOVIE_NAME = "UPDATE_MOVIE_NAME"
export const UPDATE_MOVIE_EXPERIENCE = "UPDATE_MOVIE_EXPERIENCE"
export const UPADATE_CINEMA = "UPADATE_CINEMA"
export const UPDATE_MOVIE_GENRE = "UPDATE_MOVIE_GENRE"
export const UPDATE_MOVIE_FORMAT = "UPDATE_MOVIE_FORMAT"
export const UPDATE_MOVIE_LANGUAGE = "UPDATE_MOVIE_LANGUAGE"
export const UPDATE_MOVIE_RATING = "UPDATE_MOVIE_RATING"
export const UPDATE_FOOD_CATGEORY="UPDATE_FOOD_CATGEORY"
export const UDPATE_FNB_ITEMS="UDPATE_FNB_ITEMS"
export const UPDATE_FROM_DATE="UPDATE_FROM_DATE"
export const UPDATE_TO_DATE="UPDATE_TO_DATE"
export const UPDATE_PERIOD_TYPE="UPDATE_PERIOD_TYPE"
export const UPDATE_PERIOD_COUNT="UPDATE_PERIOD_COUNT"


export const UPDATE_CUSTOMER_DOB_FROM_DATE="UPDATE_CUSTOMER_DOB_FROM_DATE"
export const UPDATE_CUSTOMER_DOB_TO_DATE="UPDATE_CUSTOMER_DOB_TO_DATE"
export const UPDATE_CUSTOMER_GENDER="UPDATE_CUSTOMER_GENDER"
export const UPDATE_CUSTOMER_TYPE="UPDATE_CUSTOMER_TYPE"

export const RESET_FILTERS = "RESET_FILTERS"

export const SUBMIT_LOADE = "SUBMIT_LOADE"
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS"
export const SUBMIT_FAIL = "SUBMIT_FAIL"

export const GET_CUSTOMER_LIST_LOADING= "GET_CUSTOMER_LIST_LOADING"
export const GET_CUSTOMER_LIST_SUCCESS= "GET_CUSTOMER_LIST_SUCCESS"
export const GET_CUSTOMER_LIST_FAIL= "GET_CUSTOMER_LIST_FAIL"
export const GET_CUSTOMER_LIST_RESET= "GET_CUSTOMER_LIST_RESET"

export const UPDATE_SEGMENTS_LOADING="UPDATE_SEGMENTS_LOADING"
export const UPDATE_SEGMENTS_SUCCESS="UPDATE_SEGMENTS_SUCCESS"
export const UPDATE_SEGMENTS_ERROR="UPDATE_SEGMENTS_ERROR"

export const FETCH_LOADER_START="FETCH_LOADER_START"
export const FETCH_LOADER_STOP="FETCH_LOADER_STOP"

export const   RESET_DATE_MODULE ="RESET_DATE_MODULE"
export const   RESET_TRANSACTION_MODULE ="RESET_TRANSACTION_MODULE"
export const   RESET_MOVIE_MODULE ="RESET_MOVIE_MODULE"
export const   RESET_FNB_MODULE ="RESET_FNB_MODULE"
export const   RESET_CUSTOMER_MODULE ="RESET_CUSTOMER_MODULE"
export const   RESET_PERIOD_MODULE ="RESET_PERIOD_MODULE"
